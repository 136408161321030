@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.contentWrapper {
  display: flex;
  flex-direction: row;
  @media #{$small-screen} {
    @include heading6($font-weight-normal, $ds-white);
    flex-direction: column;
  }
}

.medicalEventTitle {
  @include heading3($font-weight-normal, $ds-white);
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 500;
  @media #{$small-screen} {
    justify-content: center;
    @include heading6($font-weight-normal, $ds-white);
  }
}

.medicalEventDate {
  display: flex;
  align-items: center;
  @media #{$small-screen} {
    justify-content: center;
  }
}

.action {
  @media #{$small-screen} {
    display: none;
  }
  margin: 0 8px;
}
