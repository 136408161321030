@import '../../../../../styles/variables/mixins';
@import '../../../../../styles/variables/media-queries';

.container {
  @include typo-base();
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 600;
  line-height: 28px;

  @media #{$small-screen} {
    flex-wrap: wrap;
    justify-content: start;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .deceasedAndMinorLabelContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 4px;
    padding-left: 20px;
    gap: 16px;

    @media #{$small-screen} {
      flex-wrap: wrap;
      gap: 8px;
      padding: 0px;
    }
  }
}

.icon {
  display: flex;
  margin-right: 12px;
}
