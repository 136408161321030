@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.accordionTitle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  .actions {
    display: inherit;
    margin-right: 20px;
    > :not(:first-child) {
      margin-left: 8px;
    }
  }
}

.titleSpan {
  @include typo-base();
  font-weight: 600;
  font-size: toRem(18px);
  white-space: nowrap;
  @media #{$medium-screen} {
    @media #{$not-mobile} {
      font-size: 1.2vw;
    }
  }
}

.patientInfosContainer {
  font-size: toRem(13px);
  margin: 0 1rem 1rem;

  .patientName {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 6px 0;

    .infos {
      font-weight: 600;
    }
  }

  .patientName:first-child {
    padding-top: 6px;
  }

  .contact {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px 0;
    .infos {
      color: $ds-primary-default;
      font-weight: 600;
      cursor: pointer;
    }
  }
}

.withBadge {
  margin-right: toRem(10px);
  @media #{$medium-screen} {
    margin-right: toRem(5px);
  }
}
