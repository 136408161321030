@use 'src/styles/variables' as *;
@use 'src/styles/legacy/variables' as *;

.container {
  color: var(--fw-color-shades-white);
  background-color: var(--fw-color-shades-shade2);
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  &.clickable {
    cursor: pointer;
    &:hover {
      background:
        linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) no-repeat,
        linear-gradient(var(--fw-color-shades-shade2), var(--fw-color-shades-shade2)) no-repeat;
    }
  }

  @media #{$not-mobile} {
    padding: 10px 15px;
  }
}

.consultation_note {
  background-color: var(--fw-color-primary-dark);
  &.clickable {
    &:hover {
      background:
        linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) no-repeat,
        linear-gradient(var(--fw-color-primary-dark), var(--fw-color-primary-dark)) no-repeat;
    }
  }
}

.discharge_summary_note,
.surgical_operation_note {
  background-color: var(--fw-color-secondary-dark);
  &.clickable {
    &:hover {
      background-color: var(--fw-color-secondary-dark);
      background:
        linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)) no-repeat,
        linear-gradient(var(--fw-color-secondary-dark), var(--fw-color-secondary-dark)) no-repeat;
    }
  }
}

.headerText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @include typo-base();
  font-size: toRem(15px);
  font-weight: 600;

  @media #{$not-mobile} {
    font-size: toRem(21px);
    line-height: 28px;
  }
}

.tags {
  display: flex;
  flex-direction: row;

  span:first-child {
    margin-left: 0;
  }
}
